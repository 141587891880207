import React, { useState, useEffect, useContext } from 'react';
import './SongEdit.css';
import { DataContext } from '../DataContext';

const SongEdit = ({ allArtists, selectedArtist, selectedSong }) => {
    const { updateSongMetadata, tags } = useContext(DataContext); // Retrieve tags from DataContext
    const [isEditing, setIsEditing] = useState(false);
    const [editedTitle, setEditedTitle] = useState(selectedSong?.title || '');
    const [editedGenre, setEditedGenre] = useState(selectedSong?.genre || '');
    const [editedSubgenre, setEditedSubgenre] = useState(selectedSong?.subgenre || '');
    const [editedAlbumId, setEditedAlbumId] = useState(selectedSong?.albumId || '');
    const [editedNeverIdle, setEditedNeverIdle] = useState(selectedSong?.neverIdle || false);
    const [editedOndemand, setEditedOndemand] = useState(selectedSong?.ondemand || false);
    const [editedNeverNew, setEditedNeverNew] = useState(selectedSong?.neverNew || false);
    const [editedReleaseYear, setEditedReleaseYear] = useState(selectedSong?.releaseyear || '');
    const [editedTags, setEditedTags] = useState(selectedSong?.tags || []);
    const [tagSearch, setTagSearch] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);


    useEffect(() => {
        // Sync local state with selectedSong whenever selectedSong changes
        setEditedTitle(selectedSong?.title || '');
        setEditedGenre(selectedSong?.genre || '');
        setEditedSubgenre(selectedSong?.subgenre || '');
        setEditedAlbumId(selectedSong?.albumId || '');
        setEditedNeverIdle(selectedSong?.neverIdle || false);
        setEditedOndemand(selectedSong?.ondemand || false);
        setEditedNeverNew(selectedSong?.neverNew || false);
        setEditedReleaseYear(selectedSong?.releaseyear || '');
    }, [selectedSong]);

    if (!selectedSong) {
        return <p>No song selected. Please choose a song to view details.</p>;
    }

    // Get all albums of the selected artist
    const artistAlbums = selectedArtist?.albums || [];
    const albumName = artistAlbums.find((album) => album.id === selectedSong.albumId)?.name || 'Unknown Album';

    const allGenres = [...new Set((allArtists || []).flatMap((artist) =>
        (artist.albums || []).flatMap((album) =>
            (album.tracks || []).map((track) => track.genre)
        )
    ))].filter(Boolean);

    const allSubgenres = [...new Set((allArtists || []).flatMap((artist) =>
        (artist.albums || []).flatMap((album) =>
            (album.tracks || []).map((track) => track.subgenre)
        )
    ))].filter(Boolean);

    // Map selectedSong tags to their corresponding tag names
    const tagNames = editedTags.map(tagId => {
        const tag = tags.find(tag => tag.id === tagId);
        return tag ? tag.name : tagId;
    });

    const availableTags = tags.filter(tag => !editedTags.includes(tag.id));

    const handleAddTag = (tagId) => {
        setEditedTags((prevTags) => [...prevTags, tagId]);
        setTagSearch('');
    };

    const handleRemoveTag = (tagId) => {
        setEditedTags((prevTags) => prevTags.filter(id => id !== tagId));
    };

    const formatDuration = (ms) => {
        const minutes = Math.floor(ms / 60000);
        const seconds = Math.floor((ms % 60000) / 1000).toString().padStart(2, '0');
        return `${minutes}:${seconds}`;
    };

    const formatDate = (dateArray) => {
        const date = new Date(
            dateArray[0],
            dateArray[1] - 1, // Month is zero-indexed
            dateArray[2],
            dateArray[3],
            dateArray[4],
            dateArray[5]
        );
        return date.toLocaleString();
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleInputChange = (setter) => (e) => {
        setter(e.target.value);
    };

    const handleCheckboxChange = (setter) => (e) => {
        setter(e.target.checked);
    };

    const handleSaveClick = async () => {
        const updatedSong = {
            ...selectedSong,
            title: editedTitle,
            genre: editedGenre,
            subgenre: editedSubgenre,
            albumId: editedAlbumId,
            neverIdle: editedNeverIdle,
            ondemand: editedOndemand,
            neverNew: editedNeverNew,
            releaseyear: editedReleaseYear,
            tags: editedTags,
        };

        try {
            await updateSongMetadata(updatedSong);
            setIsEditing(false);
        } catch (error) {
            console.error('Error updating song metadata:', error);
        }
    };

    return (
        <div className="song-edit">
            <p><strong>Title:</strong>  {isEditing ? (
                <input
                    type="text"
                    value={editedTitle}
                    onChange={handleInputChange(setEditedTitle)}
                    className="input-modern"
                />
            ) : (
                <span>{selectedSong.title}</span>
            )}
            </p>
            <p><strong>Album:</strong> {isEditing ? (
                <select
                    value={editedAlbumId}
                    onChange={(e) => setEditedAlbumId(e.target.value)}
                    className="input-modern"
                >
                    {artistAlbums.map((album) => (
                        <option key={album.id} value={album.id}>{album.name}</option>
                    ))}
                </select>
            ) : (
                <span>{albumName}</span>
            )}</p>

            <p><strong>Genre:</strong> {isEditing ? (
                <input
                    type="text"
                    value={editedGenre}
                    onChange={(e) => setEditedGenre(e.target.value)}
                    className="input-modern"
                    list="genre-options"
                />
            ) : (
                selectedSong.genre || 'N/A'
            )}
                <datalist id="genre-options">
                    {allGenres.map((genre, index) => (
                        <option key={index} value={genre} />
                    ))}
                </datalist>
            </p>

            <p><strong>Subgenre:</strong> {isEditing ? (
                <input
                    type="text"
                    value={editedSubgenre}
                    onChange={(e) => setEditedSubgenre(e.target.value)}
                    className="input-modern"
                    list="subgenre-options"
                />
            ) : (
                selectedSong.subgenre || 'N/A'
            )}
                <datalist id="subgenre-options">
                    {allSubgenres.map((subgenre, index) => (
                        <option key={index} value={subgenre} />
                    ))}
                </datalist>
            </p>

            {/* Tags Section */}
            <p><strong>Tags:</strong></p>
            {!isEditing ? (
                // Display tags as simple text when not editing
                <div>{tagNames.length ? tagNames.join(', ') : 'No tags assigned'}</div>
            )
                : (
                    <div>
                        {/* Display tags as removable cards when editing */}
                        <div className="tag-container">
                            {editedTags.map((tagId) => {
                                const tag = tags.find(t => t.id === tagId);
                                return (
                                    <span key={tagId} className="tag-chip">
                                        {tag ? tag.name : tagId}
                                        <button className="remove-tag-button" onClick={() => handleRemoveTag(tagId)}>×</button>
                                    </span>
                                );
                            })}
                        </div>
                        {/* Tag search and dropdown for adding tags */}
                        <div className="tag-dropdown">
                            <input
                                type="text"
                                placeholder="Add a tag..."
                                value={tagSearch}
                                onChange={(e) => setTagSearch(e.target.value)}
                                onFocus={() => setShowDropdown(true)}
                                onBlur={() => setShowDropdown(false)}
                                className="input-modern"
                            />
                           {showDropdown && (
                            <div className="dropdown-list">
                                {availableTags
                                    .filter(tag => tag.name.toLowerCase().includes(tagSearch.toLowerCase()))
                                    .map(tag => (
                                        <div
                                            key={tag.id}
                                            className="dropdown-item"
                                            onMouseDown={() => handleAddTag(tag.id)} // Use onMouseDown to prevent blur
                                        >
                                            {tag.name}
                                        </div>
                                    ))}
                            </div>
                            )}
                        </div>
                    </div>
                )
            }

            <div className="checkbox-container-song">
                <label>
                    <input
                        type="checkbox"
                        checked={isEditing ? editedNeverIdle : selectedSong.neverIdle}
                        onChange={isEditing ? handleCheckboxChange(setEditedNeverIdle) : undefined}
                        readOnly={!isEditing}
                    /> Never Idle
                </label>
            </div>
            <div className="checkbox-container-song">
                <label>
                    <input
                        type="checkbox"
                        checked={isEditing ? editedOndemand : selectedSong.ondemand}
                        onChange={isEditing ? handleCheckboxChange(setEditedOndemand) : undefined}
                        readOnly={!isEditing}
                    /> On Demand
                </label>
            </div>
            <div className="checkbox-container-song">
                <label>
                    <input
                        type="checkbox"
                        checked={isEditing ? editedNeverNew : selectedSong.neverNew}
                        onChange={isEditing ? handleCheckboxChange(setEditedNeverNew) : undefined}
                        readOnly={!isEditing}
                    /> Never New
                </label>
            </div>
            <p><strong>Release Year:</strong> {isEditing ? (
                <input
                    type="number"
                    value={editedReleaseYear}
                    onChange={(e) => setEditedReleaseYear(e.target.value)}
                    className="input-modern"
                />
            ) : (
                selectedSong.releaseyear || 'N/A'
            )}</p>

            <p><strong>Duration:</strong> {formatDuration(selectedSong.durationMs)}</p>
            <p><strong>Created At:</strong> {formatDate(selectedSong.createdAt)}</p>

            {isEditing ? (
                <div className="button-container">
                    <button className="edit-button" onClick={handleSaveClick}>Save</button>
                    <button
                        className="edit-button cancel-button"
                        onClick={() => {
                            setEditedTitle(selectedSong?.title || '');
                            setEditedGenre(selectedSong?.genre || '');
                            setEditedSubgenre(selectedSong?.subgenre || '');
                            setEditedAlbumId(selectedSong?.albumId || '');
                            setEditedNeverIdle(selectedSong?.neverIdle || false);
                            setEditedOndemand(selectedSong?.ondemand || false);
                            setEditedNeverNew(selectedSong?.neverNew || false);
                            setIsEditing(false);
                        }}
                    >
                        Cancel
                    </button>
                </div>
            ) : (
                <button className="edit-button" onClick={handleEditClick}>Edit</button>
            )}
        </div>
    );
};

export default SongEdit;
