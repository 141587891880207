import React, { useState, useContext } from 'react';
import { DataContext } from '../DataContext';
import { FaPencilAlt, FaCheck, FaTimes } from 'react-icons/fa';
import './AlbumEdit.css';

const AlbumEdit = ({ selectedArtistId, selectedAlbum }) => {
  const { updateAlbum } = useContext(DataContext);
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(selectedAlbum?.name || '');

  if (!selectedAlbum) {
    return <p>No album selected. Please choose an album to view details.</p>;
  }

  const albumCoverUrl = `https://tdj-s3.s3.amazonaws.com/${selectedAlbum.id}.jpg`;

  const handleEditClick = () => {
    setIsEditing(true);
    setEditedName(selectedAlbum.name);
  };

  const handleSaveAlbumNameClick = async () => {
    if (editedName !== selectedAlbum.name) {
      await updateAlbum(selectedArtistId, selectedAlbum.id, editedName);
    }
    setIsEditing(false);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setEditedName(selectedAlbum.name); // Revert to the original name if editing is canceled
  };

  return (
    <div className="album-edit">
      <div className="album-header">
        <img src={albumCoverUrl} alt={`${selectedAlbum.name} cover`} className="album-image" />
        <div className="album-details">
          {isEditing ? (
            <div className="edit-mode">
              <input
                type="text"
                value={editedName}
                onChange={(e) => setEditedName(e.target.value)}
                className="name-input"
              />
              <FaCheck onClick={handleSaveAlbumNameClick} className="icon save-icon" />
              <FaTimes onClick={handleCancelClick} className="icon cancel-icon" />
            </div>
          ) : (
            <div className="display-mode">
              <h3 className="album-name-edit">{selectedAlbum.name}</h3>
              <FaPencilAlt onClick={handleEditClick} className="icon edit-icon" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AlbumEdit;
